import { FC } from 'react';
import AccordionProduct from '../../components/AccordionProduct/AccordionProduct';

const productsPage: FC = () => {
  return (
    <div>
      <AccordionProduct />
    </div>
  );
};
export default productsPage;
