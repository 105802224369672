import 'swiper/css/bundle';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import './SwiperCompanions.css';
import logoImg1 from '../../assets/companions/1.png';
import logoImg2 from '../../assets/companions/2.png';
import logoImg3 from '../../assets/companions/3.png';
import logoImg4 from '../../assets/companions/4.png';
import logoImg5 from '../../assets/companions/5.png';
import logoImg6 from '../../assets/companions/6.png';
import logoImg7 from '../../assets/companions/7.png';
import logoImg8 from '../../assets/companions/8.png';
import logoImg9 from '../../assets/companions/9.png';

const arrayLogos = [logoImg1, logoImg2, logoImg3, logoImg4, logoImg5, logoImg6, logoImg7, logoImg8, logoImg9];

export default function SwiperCompanions() {
  return (
    <Swiper
      className="SwiperCompanions"
      modules={[Navigation, Pagination, A11y, Autoplay]}
      breakpoints={{
        1140: { slidesPerView: 4 },
        992: { slidesPerView: 3 },
        768: { slidesPerView: 2 },
      }}
      spaceBetween={50}
      centerInsufficientSlides={true}
      grabCursor={true}
      autoHeight={true}
      autoplay={{
        delay: 1000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
      }}
      speed={2000}
      loop={true}
      navigation={false}
      pagination={false}
    >
      <div className="SwiperCompanions__box"></div>
      {arrayLogos.map((element, index) => {
        return (
          <SwiperSlide key={index}>
            <div className="SwiperCompanions__item">
              <img src={element} className="SwiperCompanions__img" alt="X" />
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
